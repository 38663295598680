<template>
  <div class="exception-modal">
    <esmp-modal
      v-model="show"
      title="Поиск услуги для делегирования"
      ok-text="Добавить"
      class-name="exception-modal__modal"
      :disabled-ok-button="!service"
      @on-ok="saveService"
    >
      <esmp-select
        ref="selectService"
        class="exception-modal__search-service"
        clearable
        placeholder="Поиск услуги"
        filterable
        :loading="loading"
        :show-search-results="showSearchResults"
        :remote-method="waitServicePrint"
        @on-query-change="setQuery"
        @on-select="setServiceSelectedVal"
        @keydown.enter.native="searchService"
      >
        <esmp-select-option
          v-for="r in serviceSearchList"
          :value="r.name"
          :label="r.name"
          :key="r.id"
        >
          {{ r.name }}
        </esmp-select-option>
      </esmp-select>
    </esmp-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'ExceptionModal',
  props: {
    isShowedExceptionModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      service: null,
      query: '',
      searchTimeoutId: null,
      loading: false,
      searchList: null,
      showSearchResults: false,
      serviceSearchList: null,
    };
  },
  computed: {
    ...mapState('user', ['selectedUser']),
    show: {
      get() {
        return this.isShowedExceptionModal;
      },
      set(newValue) {
        this.$emit('hide-modal', newValue);
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    setServiceSelectedVal(val) {
      this.service = this.serviceSearchList.find((el) => el.name === val.value);
    },
    setQuery(val) {
      this.query = val;
    },
    waitServicePrint() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (!this.query || this.query.length < 3) {
        this.showSearchResults = false;
      }
      if (this.query && this.query.length >= 3) {
        this.showSearchResults = true;
        this.loading = true;
        this.searchTimeoutId = setTimeout(() => {
          this.searchService();
        }, SEARCH_DELAY);
      } else {
        this.loading = false;
      }
    },
    async searchService() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query) {
        this.loading = true;
        const searchData = {
          allowedOnly: true,
          query: this.query,
        };

        if (
          this.selectedUser
          && this.selectedUser.userInfoByMasterSystems
        ) {
          Object.assign(searchData, {
            userInfoByMasterSystems:
            this.selectedUser.userInfoByMasterSystems,
          });
        }

        const { data } = await this.$API.services.smartSearch({
          method: 'post',
          url: '/services/smart-search',
          data: searchData,
          params: {},
        });
        this.serviceSearchList = data.services;
        this.loading = false;
      }
    },
    clearFilter() {
      this.query = '';
      this.service = null;
      this.serviceSearchList = null;
      this.$refs.selectService.reset();
    },
    saveService() {
      this.setLoading({ key: 'page', value: true });
      this.$emit('select-rule', this.service);
      this.setLoading({ key: 'page', value: false });
      this.clearFilter();
    },
  },
};
</script>

<style lang="scss">
  .exception-modal{}
</style>
